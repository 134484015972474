import React from "react";
import Layout from "../components/layout";
import * as styles from "./ivems.module.css";
import * as styleMain from "../styles/main.module.css";
import bannerPhoto2 from "../images/bannerPhoto2.png";
import blueWorkflowIcon from "../images/blueWorkflowIcon.png";
import blueDocumentIcon from "../images/blueDocumentIcon.png";
import blueReportIcon from "../images/blueReportIcon.png";
import blueEmailIcon from "../images/blueEmailIcon.png";
import blueIntegrationIcon from "../images/blueIntegrationIcon.png";
import blueProfileIcon from "../images/blueProfileIcon.png";
import blueRegistrationIcon from "../images/blueRegistrationIcon.png";
import blueEnrolmentIcon from "../images/blueEnrolmentIcon.png";
import blueTimetableIcon from "../images/blueTimetableIcon.png";
import blueResultIcon from "../images/blueResultIcon.png";
import blueCertificateIcon from "../images/blueCertificateIcon.png";
import blueFeedbackIcon from "../images/blueFeedbackIcon.png";
import blueAvetmissIcon from "../images/blueAvetmissIcon.png";
import blueUsiIcon from "../images/blueUsiIcon.png";
import blueCricosIcon from "../images/blueCricosIcon.png";
import blueStaffIcon from "../images/blueStaffIcon.png";

const Ivems = () => {
  return (
    <Layout>
      <main className={styles.ivems}>
        {/* ====== 0 banner ====== */}
        <section className={styleMain.banner}>
          <h2>Help towards a business success</h2>
          <h5>
            We focus on your business, not the computers, providing
            cost-effective systems
            <br />
            that save time, effort and money by meeting real business needs.
          </h5>
          <button className={styleMain.bannerBtn}>FIND OUT MORE</button>
          <img
            className={styleMain.bannerImg}
            src={bannerPhoto2}
            alt="banner_photo_2"
          />
        </section>
        {/* ====== 1 ivemsBrief ====== */}
        <section className={styles.ivemsBrief}>
          <h3>IVEMS</h3>
          <p>
            IVEMS is a cloud-base SaaS which offer vocational Education and
            Training &#40;VET&#41; provider with a comprehensive set of
            functions to manage their daily business and operation.
          </p>
          <p>
            Our products include Administration, Student Management, Online
            Learning, Training Management, Reporting, Finance and Accounting.
            And more functions are being delivered on the road.
          </p>
        </section>
        {/* ====== 2 ivemsAdmin ====== */}
        <section className={styles.ivemsAdmin}>
          <h3>Administration</h3>
          <p>
            IVEMS offers a full range of administrative processes through the
            Admin Portal for school administrators to streamline your business
            activities in a simple way. Our data dashboards capture and
            summaries real-time information and message from various activities
            and help administrators manager their complex and heavy workload in
            a visually aesthetic format.
          </p>
          <div className={styles.flex}>
            <article>
              <img src={blueWorkflowIcon} alt="blue_workflow_icon" />
              <h4>Workflow Manager</h4>
              <p>
                Our workflow manager is a feature that allows you to plan,
                design and track work flow processes. From automating
                notifications, reminders and invitations to updating schedules,
                compliance data, activities and enrolment statuses, the workflow
                manager streamlines business processes for you.
              </p>
            </article>
            <article>
              <img src={blueDocumentIcon} alt="blue_document_icon" />
              <h4>Document Management</h4>
              <p>
                Our document Management features enable all users to easily
                upload and share files. These can be part of a course in
                response to an online assessment, evidence of on-the-job
                training or uploaded directly.
              </p>
            </article>
            <article>
              <img src={blueReportIcon} alt="blue_report_icon" />
              <h4>Report Management</h4>
              <p>
                From creating and building your own templates, or having a
                custom report designed, Our Reporting tools enable you to build,
                manage and publish a range of reports that are running in real
                time. We make it easy to add in workflows, notification
                processes or data views — the choice is yours. Our Report
                Designer also has regulatory reporting built-in, so you'll
                always get accurate, flexible and comprehensive self-managed
                reports.
              </p>
            </article>
            <article>
              <img src={blueEmailIcon} alt="blue_email_icon" />
              <h4>Emails and Notifications</h4>
              <p>
                As an advanced business software solution, we enables email and
                SMS notifications, providing a rich set of workflows to help you
                keep on track of your reports, reminders and notifications.
              </p>
            </article>
            <article>
              <img src={blueIntegrationIcon} alt="blue_integration_icon" />
              <h4>System Integration</h4>
              <p>
                We offers a range of integration options from simple Excel data
                file transfers for nightly synchronised importlexport
                operations, through to complex real-time hand shaking via state-
                of-the-art web services. With IVEMS, you have the advantage of
                using a system that is, designed for easy integration with
                business systems such as ERP, HR, and CRM. And youll have access
                to a team of experts that can help you with numerous activities,
                from scoping and planning, to testing and deployment.
              </p>
            </article>
          </div>
        </section>
        {/* ====== 3 studManage ====== */}
        <section className={styles.studManage}>
          <h3>Student Management</h3>
          <p>
            IVEMS' Student Management features caters for everything from short
            course enrolments through to role development plans. The leamer's
            journey, starting from registration is mapped, managed, monitored
            and maintained all the way through to accreditation and
            certification.
          </p>
          <div className={styles.flex}>
            <article>
              <img src={blueProfileIcon} alt="blue_profile_icon" />
              <h4>Profile</h4>
              <p>
                Student details are captured in a central profile where
                administrators, companies, Managers and team leaders can easily
                add, find, update and monitor a student's profile including
                personal details, status of training, email communication,
                financials and compliance data. Each Student has access to their
                own secure portal where they can updates, enrol, pay online,
                access invoices, leave feedback, download training material,
                certificates and resource, launch online courses and complete
                online assessments.
              </p>
            </article>
            <article>
              <img src={blueRegistrationIcon} alt="blue_registration_icon" />
              <h4>Registration</h4>
              <p>
                IVEMS offers flexible options for companies and students to
                create their own account. Administrators can customize the
                registration form to collect the information relevant to your
                needs. Once a student is registered within the system, their
                profile will be created and they will receive a verification
                email. All emails and notifications are content managed by the
                administrator.
              </p>
            </article>
            <article>
              <img src={blueEnrolmentIcon} alt="blue_enrolment_icon" />
              <h4>Enrolments</h4>
              <p>
                Once a student has registered they can access their member's
                portal to search and enrol into desired courses and programs.
                Administrators, Managers, Companies and Team Leaders can also
                create enrolments for individuals and batches of students
                through Trainee Groups. Automatic email notifications to the
                relevant roles are sent when an enrolment is finalized. Flexible
                settings give admin control over enrolment statuses, payment
                options, enrolment periods, notifications and course owners.
              </p>
            </article>
            <article>
              <img src={blueTimetableIcon} alt="blue_timetable_icon" />
              <h4>Timetabling and Attendance</h4>
              <p>
                Our Timetabling and Attendance features help you to monitor and
                report student participation in learning and assessment
                activities. These features enable you to monitor attendance in
                accordance with CRICOS requirements identified in the ESOS
                reporting standards for overseas students. Admin can define what
                is deemed to be unsatisfactory attendance and be notified When a
                student falls below the threshold. As well as the comprehensive
                Timetabling and Attendance feature, the system also supports
                simplified attendance tools such as roll call and custom
                attendance templates.
              </p>
            </article>
            <article>
              <img src={blueResultIcon} alt="blue_result_icon" />
              <h4>Resulting</h4>
              <p>
                From individual to bulk marking, updating outcome codes,
                tracking assessments and assigning trainers. IVEMS offers
                flexible resulting tools for all delivery modes including
                elearning, face to face and blended. Resulting can be as simple
                as Competent, Not Yet Competent or as detailed as a percentages
                and grades. Resulting is completed by administrators or
                trainers. Recognising the need for control, authorisation and
                security, IVEMS offers the ability to change access rights
                giving only nominated personnel, such as trainers and top-level
                admin, the authorization to perform tasks such as result & issue
                certificates.
              </p>
            </article>
            <article>
              <img src={blueCertificateIcon} alt="blue_certificate_icon" />
              <h4>Certificates</h4>
              <p>
                IVEMS has several certification features that make our process
                unique. For example, some of our main resource tools include the
                ability to create certificate templates within the system, a
                certificate register to identify which certificates have been
                issued, QR verification codes for external reviewers, the
                facility to store and record certificates for students issued by
                external providers &#40;e.g first aid certificate&#41; and much
                more.
              </p>
            </article>
            <article>
              <img src={blueFeedbackIcon} alt="blue_feedback_icon" />
              <h4>Feedback</h4>
              <p>
                The IVEMS feedback feature enables users to provide feedback to
                the provider. Students can participate in complaints and appeals
                via this feature and have access via their student portal.
                Trainers, staff and other users can also provide feedback that
                might be used for continuous improvement. The IVEMS feedback
                feature includes a complete back-end follow up and corrective
                action function via the admin portal.
              </p>
            </article>
          </div>
        </section>
        {/* ====== 4 courseManage ====== */}
        <section className={styles.courseManage}>
          <h3>Course Management</h3>
          {/* <p>
            This is a descriptive text. This is a descriptive text. This is a
            descriptive text. This is a descriptive text. This is a descriptive
            text. This is a descriptive text. This is a descriptive text. This
            is a descriptive text. This is a descriptive text. This is a
            descriptive text. This is a descriptive text. This is a descriptive
            text. This is a descriptive text. This is a descriptive text. This
            is a descriptive text. This is a descriptive text. This is a
            descriptive text. This is a descriptive text. This is a descriptive
            text. This is a descriptive text. This is a descriptive text. This
            is a descriptive text. This is a descriptive text. This is a
            descriptive text. This is a descriptive text. This is a descriptive
            text. This is a descriptive text. This is a descriptive text. This
            is a descriptive text. This is a descriptive text.
          </p> */}
          <div className={styles.flex}>
            <article>
              <h4>Course Planning</h4>
              <p>
                Course Planning is the process of constructing learning
                components in a way that can be delivered most effectively to
                your learners. IVEMS provides course design options enabling you
                to construct simple &#40;stand alone units&#41; to complex
                courses &#40;units broken into stages of learning eg terms
                semesters&#41;. Once the course has been designed, the
                curriculum structure can be reviewed and approved using our
                curriculum planner.
              </p>
            </article>
            <article>
              <h4>Timetable</h4>
              <p>
                Our Timetable feature enables admin to creates activities,
                allocate trainers and book venues where training will take place
                Trainers can log in to their portal, see activities assigned to
                them, mark attendance and add notes where needed. Timetables are
                configured by administrators allowing them to designate public
                holidays. organisation breaks and default activity times.
                Timetables also act as the basis for attendance marking.
              </p>
            </article>
            <article>
              <h4>Assessments</h4>
              <p>
                Assessments are the tools used to establish competency in a
                skill and are provided across all learning modes including
                face-to- face, online and blended and are assigned a category
                and type. Assessments can be created and added to the Base
                Assessment Library for a course designer to use when creating a
                course. When a student is enrolled into a course, assessments
                are automatically inherited to be marked Assessments can also be
                added on the fly when marking results.
              </p>
            </article>
            <article>
              <h4>Scheduling</h4>
              <p>
                The scheduling feature includes the specifics of a course and
                notifies students and trainers of a course's location, time,
                delivery mode, pricing and availability. It also enables admin
                to identify and assign the activities and resources to the
                course. Administrators have flexible options around schedule
                visibility When enrolling online, students are provided with
                schedule information and have the option to proceed to enrolment
                or submit an enquiry.
              </p>
            </article>
          </div>
        </section>
        {/* ====== 5 ivemsCompliance ====== */}
        <section className={styles.ivemsCompliance}>
          <aside>
            <h3>Compliance</h3>
            {/* <p>
              Course Planning is the process of constructing learning components
              in a way that can be delivered most effectively to your learners.
              IVEMS provides course design options enabling you to construct
              simple &#40;stand alone units&#41; to complex courses &#40;units
              broken into stages of learning eg terms, semesters&#41;. Once the
              course has been designed, the curriculum structure can be reviewed
              and approved using our curriculum.
            </p> */}
          </aside>
          <div className={styles.flex}>
            <article>
              <img src={blueAvetmissIcon} alt="blue_avetmiss_icon" />
              <h4>AVETMISS</h4>
              <p>
                As an AVETMISS Compliant SMS, IVEMS helps you collect learner
                data and formats it for NCVER or for your State Training
                Authority. Our AVETMISS feature ensures your system is updated
                with the latest requirements Default AVETMISS values are
                configured to be inherited by courses; enrolments automatically
                inherit the details from the course. Admin can easily adjust
                individuals records and quickly run the data through Our
                AVETMISS Validation checker to ensure the data is error free and
                fix any discrepancies prior to the final submission.
              </p>
            </article>
            <article>
              <img src={blueUsiIcon} alt="blue_usi_icon" />
              <h4>USI</h4>
              <p>
                The Unique Student Identifier (USI) is a mandatory reference
                number for all students enrolled in nationally recognised
                training in Australia. A USI is required in order to receive
                your Qualifications or Statement of Attainment and provides an
                online record of training. IVEMS provides facilities to create,
                verify and locate a student's USI number either individually or
                in bulk. IVEMS provides flexible options to prevent accidentally
                issuing AQF certificates without a USI or finalizing a
                student-made enrolment with no USI.
              </p>
            </article>
            <article>
              <img src={blueCricosIcon} alt="blue_cricos_icon" />
              <h4>CRICOS</h4>
              <p>
                Our CRICOS module is a suite of features enabling you to capture
                international student details including passport, visa, IELTS,
                overseas address and health cover information. From application,
                offer, acceptance and enrolment through to certification the
                students journey is monitored to help you stay on top of your
                ESOS standard obligations. Agent partnerships are identified and
                students can be associated helping you to monitor commission and
                discounts.
              </p>
            </article>
            <article>
              <img src={blueStaffIcon} alt="blue_staff_icon" />
              <h4>Staff Matrix</h4>
              <p>
                Our Staff Qualification Register features will help you to keep
                track of your trainer and assessor capabilities, qualifications,
                professional development activities and performance reviews.
                This allows you to easily run reports to demonstrate that your
                trainers and assessors have the necessary credentials to perform
                their roles.
              </p>
            </article>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Ivems;

export const Head = () => <title>Auokka | IVEMS</title>;
